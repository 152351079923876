import { LocalStorage } from '../localStorage/LocalStorage.services'

const putHeadquarter = async (data, id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/arees/${id}`, 
            {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': LocalStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
        
        if (response.ok) {
            const data = await response.json()
            return  data
        }
    } catch (error) {
        return({
            message: error.message || 'Something went wrong!'
        })
    }
}

export default putHeadquarter
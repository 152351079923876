import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'

import styles from './Login.module.css'

const Login = (props) => {

    const refEmail = useRef("")
    const refPassword = useRef("")
    const [ visible, setVisible ] = useState(false)
    const [ typeInput, setTypeInput ] = useState("password")

    const handelSubmit = (event) => {
        event.preventDefault()
        const loginData = {
            professionalUser: refEmail.current.value,
            professionalPassword: refPassword.current.value,
        }
        props.onLogin(loginData)
    }

    const handleVisible = () => {
        setVisible(!visible)
        typeInput === "password" ? setTypeInput("text") : setTypeInput("password") 
    }

    return (
        <div className={styles.bodyLogin} data-theme='dark'>
            <div className={styles.containerLogin}>
                <div className={styles.formContainer} id='formContainer'>
                    <form className={styles.formLogin} onSubmit={handelSubmit}>
                        <h1>Sign In</h1>
                        <div className={styles.socialIcons}>
                            <Link to="#" className={styles.icon}><i className="fa-brands fa-windows"></i></Link>
                            <Link to="#" className={styles.icon}><i className="fa-brands fa-twitter"></i></Link>
                            <Link to="#" className={styles.icon}><i className="fa-brands fa-linkedin-in"></i></Link>
                            <Link to="#" className={styles.icon}><i className="fa-solid fa-envelope"></i></Link>
                        </div>
                        <span>Utilitza el teu email com a email password</span>
                        <input ref={refEmail} className={styles.email} type='email' name='professionalname' placeholder='Usuari' ></input>
                        <div className={styles.password_div}>
                            <input ref={refPassword} className={styles.password} type={typeInput} name='professionalpass' placeholder='Contrasenya' ></input>
                            { visible ? <i className="fa-regular fa-eye" onClick={handleVisible}></i> : <i className="fa-regular fa-eye-slash" onClick={handleVisible}></i> }
                        </div>
                        <button type='submit'>Sign In</button>
                        <Link href="#">Has oblidat el Password?</Link>
                    </form>
                </div>
                <div className={styles.toggleContainer}>
                    <div className={styles.toggle}>
                        <div className={styles.togglePanel} id='toogle-left'>
                            <h1>Benvinguts!</h1>
                            <p>Esteu apunt d'accedir al programa</p> 
                            <p>de SocialApp</p>
                            <p></p>
                            <p>Teniu alguna incidència o recomanació?</p>
                            <button className={styles.hidden} id='login'>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </div>
    )
}

export default Login
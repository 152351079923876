import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'

import getAllReferents from '../../services/referents/getAllReferents'
import desactiveReferent from '../../services/referents/desactiveReferent'
import getAllProfessionals from '../../services/professionals/getAllProfessional'
import getAllUsers from '../../services/users/getAllUsers'

import styles from './Referents.module.css'

const Referents = () => {

  const componentRef = useRef()

  const [ pageSize, setPageSize ] = useState(20)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ numberOfPages, setNumberOfPages ] = useState(0)
  const [ currentDataDisplayed, setCurrentDataDisplayed ] = useState([])
  const [ previousAllowed, setPreviousAllowed ] = useState(false)
  const [ nextAllowed, setNextAllowed ] = useState(true)
  const [ isChecked, setIsChecked ] = useState([])
  
  const [ referents, setReferents ] = useState([])
  const [ referentsFiltered, setReferentsFiltered ] = useState([])
  const [ referentsFiltered1, setReferentsFiltered1 ] = useState([])
  const [ referentsFiltered2, setReferentsFiltered2 ] = useState([])
  const [ referentsFiltered3, setReferentsFiltered3 ] = useState([])
  const [ professionals, setProfessionals ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ update, setUpdate ] = useState(false)

  useEffect(() => {
    getAllReferents()
    .then(referents => {
      setReferents(referents)
      setReferentsFiltered(referents)
      setReferentsFiltered1(referents)
      setReferentsFiltered2(referents)
      setReferentsFiltered3(referents)
    })
    getAllProfessionals()
    .then(professionals => {
      setProfessionals(professionals)
    })
    getAllUsers()
    .then(users => {
      setUsers(users)
    })
  },[update])

  useEffect(() => {
    referentsFiltered &&
      setCurrentDataDisplayed(() => {
        const page = referentsFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return page
      })
      setIsChecked(() => {
        const page = referentsFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return (new Array(page.length).fill(false))
      })
      setPreviousAllowed(() => currentPage > 1)
      setNextAllowed(() => currentPage < numberOfPages)
      setNumberOfPages(Math.ceil(referentsFiltered.length / pageSize))
  }, [currentPage, referentsFiltered, pageSize, update, numberOfPages])

  // Actualizar els seguimients filtrats combinant ambdos filtres
  useEffect(() => {
    const uniqueValues = getCommonValues(referentsFiltered1, referentsFiltered2, referentsFiltered3)
    setReferentsFiltered(uniqueValues)
  }, [referentsFiltered1, referentsFiltered2, referentsFiltered3])
  
  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return
      setCurrentPage((prevState) => (prevState -= 1))
    }
    if (action === "next") {
      if (!nextAllowed) return
      setCurrentPage((prevState) => (prevState += 1))
    }
  }

  const handleDesactiveMultiple = (event) => {
    const arrayId = []
    for (let index = 0; index < isChecked.length; index++) {
      arrayId[index] = currentDataDisplayed[index]._id
    }
    for (let index = 0; index < isChecked.length; index++) {
      isChecked[index] && desactiveReferent(arrayId[index])
    }
    setUpdate(!update)
  }

  const handleDesactive = (event) => {
    desactiveReferent(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  // Función para canviar la paginación de la tabla
  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value))
  }

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "Taula de referents",
    onafterprint: () => alert('Print success')
  })
  
  const titles = [ "", "Referent", "Usuaris", "Estat", "Accions" ]

  function getCommonValues(...arrays) {
    return arrays.reduce((acc, currentArray) => {
      return acc.filter((value) => currentArray.includes(value));
    });
  }
  
  // Función para hacer funcionar el filtro de elementos => por tipus de professional
  const handleChangeProfessional = (value) => {
    if (value.length !== 0) {
      let referentsFiltereds = referents.filter(element =>  value.includes(element.referentProfessionalId._id)) 
      setReferentsFiltered1(referentsFiltereds)
    } else {
      setReferentsFiltered1(referents)
    }
  }

  // Placeholder del filtro de tipus de professional
  const placeholderFilterProfessionaType = "Filtre professional"

  // Array con los elementos del filtro de tipus de professional
  const dataProfessionals = professionals.map(element => {
    return {
      label: `${element.professionalLastName1} ${element.professionalLastName2}, ${element.professionalName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por tipus de usuari
  const handleChangeUser = (value) => {
    if (value.length !== 0) {
      let referentsFiltereds = referents.filter(element => 
        value.some(option => element.brigadaUsersId.some(user => user._id === option))
      ) 
      setReferentsFiltered2(referentsFiltereds)
    } else {
      setReferentsFiltered2(referents)
    }
  }

  // Placeholder del filtro de tipus de usuari
  const placeholderFilterUsers = "Filtre usuaris"

  // Array con los elementos del filtro de tipus de usuari
  const dataUsers = users.map(element => {
    return {
      label: `${element.userLastName1} ${element.userLastName2}, ${element.userName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por actiu o no actiu
  const active = [
    {
      name: "Actiu",
      value: true,
    },
    {
      name: "No actiu",
      value: false
    }
  ]
  
  const handleChangeActive = (value) => {
    if (value.length !== 0) {
      let referentsFiltereds = referents.filter(element =>  value.includes(element.brigadaActive)) 
      setReferentsFiltered3(referentsFiltereds)
    } else {
      setReferentsFiltered3(referents)
    }
  }

  // Placeholder del filtro de usuaris
  const placeholderFilterActive = "Filtre actiu"

  // Array con los elementos del filtro de usuaris
  const dataActive = active.map(element => {
    return {
      label: element.name,
      value: element.value
    }
  })

  const handelSelectedReferents = (position) => {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === position ? !item : item
    )
    setIsChecked(updatedCheckedState)
  }
  
  const handelSelectedAllReferents = (event) => {
    const newValues = []
    if (event.target.checked === true) {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = true
      }
    } else {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = false
      }
    }
    setIsChecked(newValues)
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
              data={dataProfessionals} 
              handleChange={handleChangeProfessional}
              placeholderFilter={placeholderFilterProfessionaType}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataUsers} 
              handleChange={handleChangeUser}
              placeholderFilter={placeholderFilterUsers}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataActive} 
              handleChange={handleChangeActive}
              placeholderFilter={placeholderFilterActive}
              type='multiple'
            />
          </div>
        </div>
        <div className={styles.button}>
          <div className={styles.pagination}>
            <button onClick={() => handlePagination("prev")}><i className="fa-solid fa-backward"></i></button>
            <button onClick={() => handlePagination("next")}><i className="fa-solid fa-forward"></i></button>
            <p>
              <span>{pageSize * (currentPage - 1)}</span> a <span>{currentDataDisplayed && currentDataDisplayed.length+(currentPage - 1) * pageSize}</span> de <span>{referentsFiltered?.length}</span> resultats
            </p>
            <div className={styles.view}>
              <span>Mostrar </span>
              <select onChange={handleChangePageSize} value={pageSize}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> resultats</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button handleOnClick={handleDesactiveMultiple} icon={"fa-trash"}/>
            <Button path="/referents/new-referent" icon={"fa-circle-plus"}/>
          </div>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3" className={styles.etable}>
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return index === 0 ?
                        <th className={styles.columns} key={index}><input type='checkbox' onChange={handelSelectedAllReferents}></input></th>  :
                        <th className={styles.columns} key={index}>{element}</th>
                    })
                }
              </tr>
            </thead>
            <tbody>
            {currentDataDisplayed
              ? currentDataDisplayed.map((element, index) => {
                  let url = '/referents/' + element._id
                  let checkedItem = isChecked[index]
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>
                          <input type='checkbox' id={index} value={element._id} checked={checkedItem} onChange={() => handelSelectedReferents(index)}></input>
                      </td>
                      <td className={styles.columns}>{element.referentProfessionalId.professionalName} {element.referentProfessionalId.professionalLastName1} {element.referentProfessionalId.professionalLastName2}</td>
                      <td className={styles.columns}>
                        {
                          element.referentUsersId && element.referentUsersId.map(element => {
                            return (<>
                              {element.userLastName1} {element.userLastName2}, {element.userName}<br></br>
                            </>)
                          })
                        }
                      </td>
                      <td className={styles.columns}>{element.referentActive ? "Activa" : "No activa"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                }) : null
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Referents

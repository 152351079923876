import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getPayTypeById from '../../services/payType/getPayTypeById'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import postPayType from '../../services/payType/postPayType'
import putPayType from '../../services/payType/putPayType'

import styles from './NewPayType.module.css'

const NewHeadquarter = () => {

    let navigate = useNavigate()

    const { id } = useParams()
    const [ newPayType, setNewPayType ] = useState({})
    const [ active, setActive ] = useState(true)
    const [ companies, setCompanies ] = useState([])
    const [ area, setArea ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllCompanies()
        .then(companies => {
            setCompanies(companies)
        })
        getAllHeadquarters()
        .then(arees => {
            setArea(arees)
        })
        if (id !== undefined) {
            getPayTypeById(id)
            .then(payType => {
                setNewPayType(payType)
                setActive(payType.payTypeActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('payTypeActive', event.target.payTypeActive.checked)
        const formJson = Object.fromEntries(formData.entries())
        if (id !== undefined) {
            putPayType(formJson, id) 
            .then(newPayType => {
                setNewPayType(newPayType)
            })
        } else {
            postPayType(formJson) 
            .then(newPayType => {
                setNewPayType(newPayType)
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/pay-type", { replace: true })
        },3000)
    }

    const handleChange = (event) => {
        if (event.target.id === "payTypeActive") {
            setActive(event.target.checked)
        } else {
            setNewPayType(event.target.value)
        }
    }

    return (
        <div className={styles.container}>
            <h2>{id ? "Editar Sistema Pagament" : "Alta nou Sistema Pagament"}</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Sistema Pagament modificat correctament" : "Sistema Pagament creat correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="payTypeName">Sistema Pagament</label>
                <input type='text' onChange={handleChange} name="payTypeName" value={newPayType.payTypeName}></input>
                <label for="payTypeAreaId">Àrea</label>
                <select onChange={handleChange} name="payTypeAreaId" value={newPayType.payTypeAreaId} id="payTypeAreaId" required>
                    {
                        area.map( element => {
                            return (
                                <option value={element._id}>{element.areaName}</option>
                            )
                        })
                    }
                </select>
                <label for="payTypeActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="payTypeActive" id="payTypeActive" checked={active} className={styles.checking}/>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? <i className="fa-regular fa-floppy-disk"></i> : <i className="fa-solid fa-circle-plus"></i>}{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewHeadquarter
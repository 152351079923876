import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import desactiveHeadquarter from '../../services/headquartes/desactiveHeadquarter'

import styles from './Headquarters.module.css'

const Headquarters = () => {
    
  const componentRef = useRef()

  const [ arees, setArees ] = useState([])
  const [ areesFiltered, setAreesFiltered ] = useState([])
  const [ areesFiltered1, setAreesFiltered1 ] = useState([])
  const [ areesFiltered2, setAreesFiltered2 ] = useState([])
  const [ companies, setCompanies ] = useState([])
  const [ update, setUpdate ] = useState(false)

  useEffect(() => {
    getAllHeadquarters()
    .then(arees => {
      setArees(arees)
      setAreesFiltered(arees)
      setAreesFiltered1(arees)
      setAreesFiltered2(arees)
    })
    getAllCompanies()
    .then(companies => {
      setCompanies(companies)
    })
  },[update])

  // Actualizar els seguimients filtrats combinant ambdos filtres
  useEffect(() => {
    const uniqueValues = getCommonValues(areesFiltered1, areesFiltered2)
    setAreesFiltered(uniqueValues)
  }, [areesFiltered1, areesFiltered2])

  function getCommonValues(...arrays) {
    return arrays.reduce((acc, currentArray) => {
      return acc.filter((value) => currentArray.includes(value))
    })
  }

  const handleDesactive = (event) => {
    desactiveHeadquarter(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula d'arees",
    onafterprint: () => alert('Print success')
  })

  const titles = [ "Centre", "Estat", "Accions" ]

   // Función para hacer funcionar el filtro de elementos => por Empresa
   const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      let areesFiltereds = arees.filter(element =>  value.includes(element.areaCompanyId._id)) 
      setAreesFiltered1(areesFiltereds)
    } else {
      setAreesFiltered1(arees)
    }
  }

  // Placeholder del filtro de empresa
  const placeholderFilterCompanies = "Filtre empresa"

  // Array con los elementos del filtro de empresa
  const dataCompanies = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por Àrea
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      let areesFiltereds = arees.filter(element =>  value.includes(element._id)) 
      setAreesFiltered2(areesFiltereds)
    } else {
      setAreesFiltered2(arees)
    }
  }

  // Placeholder del filtro de areas
  const placeholderFilterHeadquarters = "Filtre centre"

  // Array con los elementos del filtro de areas
  const dataHeadquarters = arees.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })

  return (
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.filters}>
            <div className={styles.multiselector}>
              <Filter
                data={dataCompanies} 
                handleChange={handleChangeCompany}
                placeholderFilter={placeholderFilterCompanies}
                type='multiple'
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataHeadquarters} 
                handleChange={handleChangeHeadquarter}
                placeholderFilter={placeholderFilterHeadquarters}
                type='multiple'
              />
            </div>
          </div>
        <div className={styles.button}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button path="/centres/new-centre" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3" className={styles.etable}>
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                areesFiltered.map(element => {
                  let url = '/centres/' + element._id
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.areaName}</td>
                      <td className={styles.columns}>{element.areaActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Headquarters

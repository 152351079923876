import { useState } from 'react'
import { Routes, Route, Navigate, Link } from 'react-router-dom'
import { LocalStorage } from './services/localStorage/LocalStorage.services'

import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'
import LoginPage from './views/Login/LoginPage'
import Home from './views/Home/Home'
import Professionals from './views/Professionals/Professionals'
import NewProfessional from './views/Professionals/NewProfessional'
import Referents from './views/Referents/Referents'
import NewReferent from './views/Referents/NewReferent'
import Arees from './views/Headquarters/Headquarters'
import NewArea from './views/Headquarters/NewHeadquarter'
import Companies from './views/Companies/Companies'
import NewCompany from './views/Companies/NewCompany'
import User from './views/Users/Users'
import NewUser from './views/Users/NewUser'
import ViewUser from './views/Users/ViewUser'
import SeguimentType from './views/SeguimentType/SeguimentType'
import NewSeguimentType from './views/SeguimentType/NewSeguimentType'
import Seguiment from './views/Seguiments/Seguiments'
import NewSeguiment from './views/Seguiments/NewSeguiment'
import PayType from './views/PayType/PayType'
import NewPayType from './views/PayType/NewPayType'
import EditSeguiment from './views/Seguiments/EditSeguiment'
import CalendarSeguiments from './views/Seguiments/CalendarSeguiments'
import image from "./assets/img/logo_sant-tomas.png"

import styles from './App.module.css'

function App() {

  // Guardar estat del Login: logejat o no logejat
  const [ isLogged, setIsLogged ] = useState(false)
  const [ tokenExpired, setTokenExpired ] = useState(false)

  const professionalProfile = LocalStorage.getItem("professionalProfile")

  const handleLogout = () => {
    LocalStorage.clear()
    setIsLogged(false)
  }

  return (
    <div className={styles.container}>
    {isLogged && !tokenExpired ? <>
      <header className={styles.headerSi}>
        <a href="https://www.santtomas.cat/" className={styles.logo} target='blank'><img src={image} alt="logo" /></a>
        <NavBar professionalProfile={professionalProfile}></NavBar>
        <Link className={styles.logout} onClick={handleLogout} to="/"><span>Logout</span><i className="fa-solid fa-right-from-bracket"></i></Link>
      </header>
      <main className={styles.main}>
          <Routes>
            <Route path="/" element={<Navigate to='/home'></Navigate>}></Route>
            {/* <Route path="/" element={!isLogged && !tokenExpired ? <LoginPage isLogged={isLogged} setIsLogged={setIsLogged}></LoginPage> : <Navigate to='/home'></Navigate>}></Route> */}
            <Route path="/home" element={<Home></Home>}/>
            <Route path="/usuaris/">
              <Route index element={<User professionalProfile={professionalProfile}></User>}/>
              <Route path="new-usuari" element={<NewUser></NewUser>}/>
              <Route path=":id" element={<NewUser></NewUser>}/>
              <Route path="fitxa-usuari/:id" element={<ViewUser></ViewUser>}/>
            </Route>
            <Route path="/professionals/">
              <Route index element={<Professionals professionalProfile={professionalProfile}></Professionals>}/>
              <Route path="new-professional" element={<NewProfessional></NewProfessional>}/>
              <Route path=":id" element={<NewProfessional></NewProfessional>}/>
            </Route>
            <Route path="/referents/">
              <Route index element={<Referents></Referents>}/>
              <Route path="new-referent" element={<NewReferent></NewReferent>}/>
              <Route path=":id" element={<NewReferent></NewReferent>}/>
            </Route>
            <Route path="/calendarilist/">
              <Route index element={<CalendarSeguiments></CalendarSeguiments>}/>
            </Route>
            <Route path="/seguiments/">
              <Route index element={<Seguiment></Seguiment>}/>
              <Route path="new-seguiment" element={<NewSeguiment></NewSeguiment>}/>
              <Route path=":id" element={<EditSeguiment></EditSeguiment>}/>
              <Route path="seguiments-calendar" element={<CalendarSeguiments></CalendarSeguiments>}/>
            </Route>
            <Route path="/seguiment-type/">
              <Route index element={<SeguimentType></SeguimentType>}/>
              <Route path="new-seguimenttype" element={<NewSeguimentType></NewSeguimentType>}/>
              <Route path=":id" element={<NewSeguimentType></NewSeguimentType>}/>
            </Route>
            <Route path="/pay-type/">
              <Route index element={<PayType></PayType>}/>
              <Route path="new-pay" element={<NewPayType></NewPayType>}/>
              <Route path=":id" element={<NewPayType></NewPayType>}/>
            </Route>
            <Route path="/centres/">
              <Route index element={<Arees></Arees>}/>
              <Route path="new-centre" element={<NewArea></NewArea>}/>
              <Route path=":id" element={<NewArea></NewArea>}/>
            </Route>
            <Route path="/empreses/">
              <Route index element={<Companies setTokenExpired={setTokenExpired}></Companies>}/>
              <Route path="new-empresa" element={<NewCompany></NewCompany>}/>
              <Route path=":id" element={<NewCompany></NewCompany>}/>
            </Route>
          </Routes>
        </main>
        <footer className={styles.footer}>
          <Footer></Footer>
        </footer> 
      </>
       :
       <LoginPage isLogged={isLogged} setIsLogged={setIsLogged}></LoginPage> 
     }
    </div>
  )
}

export default App

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getProfessionalById from '../../services/professionals/getProfessionalById'
import postProfessional from '../../services/professionals/postProfessional'
import putProfessional from '../../services/professionals/putProfessional'

import styles from './NewProfessional.module.css'

const NewProfessional = () => {

    let navigate = useNavigate()

    const { id } = useParams()
    const [ newProfessional, setNewProfessional ] = useState({})
    const [ active, setActive ] = useState(true)
    const [ headquarters, setHeadquarters ] = useState([])
    const [ companies, setCompanies ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllHeadquarters()
        .then(headquarters => {
            setHeadquarters(headquarters)
        })
        getAllCompanies()
        .then(companies => {
            setCompanies(companies)
        })
        if (id !== undefined) {
            getProfessionalById(id)
            .then(Professional => {
                setNewProfessional(Professional)
                setActive(Professional.professionalActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('professionalActive', event.target.professionalActive.checked)
        const formJson = Object.fromEntries(formData.entries())
        if (id !== undefined) {
            putProfessional(formJson, id) 
            .then(newProfessional => {
                setNewProfessional(newProfessional)
            })
        } else {
            postProfessional(formJson) 
            .then(newProfessional => {
                setNewProfessional(newProfessional);
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/professionals", { replace: true })
        },3000)
    }

    const handleChange = (event) => {
        if (event.target.id === "professionalActive") {
            setActive(event.target.checked)
        } else {
            setNewProfessional(event.target.value);
        }
    }

    return (
        <div className={styles.container}>
            <h2>{id ? "Editar Professional" : "Alta nou Professional"}</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Professional modificat correctament" : "Professional creat correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="professionalName">Nom</label>
                <input type='text' onChange={handleChange} name="professionalName" value={newProfessional.professionalName}></input>
                <label for="professionalLastName1">Cognom 1</label>
                <input type='text' onChange={handleChange} name="professionalLastName1" value={newProfessional.professionalLastName1}></input>
                <label for="professionalLastName2">Cognom 2</label>
                <input type='text' onChange={handleChange} name="professionalLastName2" value={newProfessional.professionalLastName2}></input>
                <label for="professionalDNI">DNI</label>
                <input type='text' onChange={handleChange} name="professionalDNI" value={newProfessional.professionalDNI}></input>
                <label for="professionalEmail">E-mail</label>
                <input type='email' onChange={handleChange} name="professionalEmail" value={newProfessional.professionalEmail}></input>
                <label for="professionalUser">Usuari</label>
                <input type='text' onChange={handleChange} name="professionalUser" value={newProfessional.professionalUser}></input>
                <label for="professionalPassword">Contrasenya</label>
                <input type='password' onChange={handleChange} name="professionalPassword" ></input>
                <label for="professionalProfile">Perfil</label>
                <select onChange={handleChange} name="professionalProfile" value={newProfessional.professionalProfile} id="professionalProfile">
                    <option value="selecciona">- Selecciona -</option>
                    <option value="Administrador">Administrador</option>
                    <option value="Psicomotricitat">Psicomotricitat</option>
                    <option value="Psicologia">Psicologia</option>
                    <option value="Logopedia">Logopedia</option>
                    <option value="Fisioteràpia">Fisioteràpia</option>
                </select>
                <label for="professionalAreaId">Àrea</label>
                <select onChange={handleChange} name="professionalAreaId" value={newProfessional.professionalAreaId} id="professionalAreaId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        headquarters.map( element => {
                            return (
                                <option value={element._id}>{element.areaName}</option>
                            )
                        })
                    }
                </select>
                <label for="professionalCompanyId">Empresa</label>
                <select onChange={handleChange} name="professionalCompanyId" value={newProfessional.professionalCompanyId} id="professionalCompanyId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        companies.map( element => {
                            return (
                                <option value={element._id}>{element.companyName}</option>
                            )
                        })
                    }
                </select>
                <label for="professionalActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="professionalActive" id="professionalActive" checked={active} className={styles.checking} />
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewProfessional
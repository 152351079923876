import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getHeadquarterById from '../../services/headquartes/getHeadquarterById'
import getAllCompanies from '../../services/companies/getAllCompanies'
import postHeadquarter from '../../services/headquartes/postHeadquarter'
import putHeadquarter from '../../services/headquartes/putHeadquarter'

import styles from './NewHeadquarter.module.css'

const NewHeadquarter = () => {

    let navigate = useNavigate()

    const { id } = useParams()
    const [ newHeadquarter, setnewHeadquarter ] = useState({})
    const [ active, setActive ] = useState(true)
    const [ companies, setCompanies ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllCompanies()
        .then(companies => {
            setCompanies(companies)
        })
        if (id !== undefined) {
            getHeadquarterById(id)
            .then(headquarter => {
                setnewHeadquarter(headquarter)
                setActive(headquarter.areaActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('areaActive', event.target.areaActive.checked)
        const formJson = Object.fromEntries(formData.entries())
        if (id !== undefined) {
            putHeadquarter(formJson, id) 
            .then(newHeadquarter => {
                setnewHeadquarter(newHeadquarter);
            })
        } else {
            postHeadquarter(formJson) 
            .then(newProfessional => {
                setnewHeadquarter(newProfessional);
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/centres", { replace: true })
        },3000)
    }

    const handleChange = (event) => {
        if (event.target.id === "areaActive") {
            setActive(event.target.checked)
        } else {
            setnewHeadquarter(event.target.value);
        }
    }

    return (
        <div className={styles.container}>
            <h2>{id ? "Editar centre" : "Alta nou centre"}</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Centre modificat correctament" : "Crentre creat correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="areaName">Centre</label>
                <input type='text' onChange={handleChange} name="areaName" value={newHeadquarter.areaName}></input>
                <label for="areaCompanyId">Empresa</label>
                <select onChange={handleChange} name="areaCompanyId" value={newHeadquarter.areaCompanyId} id="areaCompanyId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        companies.map( element => {
                            return (
                                <option value={element._id}>{element.companyName}</option>
                            )
                        })
                    }
                </select>
                <label for="areaActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="areaActive" id="areaActive" checked={active} className={styles.checking}/>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? <i className="fa-regular fa-floppy-disk"></i> : <i className="fa-solid fa-circle-plus"></i>}{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewHeadquarter
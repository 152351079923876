import { Select } from 'antd';

const Filter = ({ data, handleChange, placeholderFilter, value, type, handleSelect, id }) => {
    return (
        <Select
            mode={type}
            allowClear
            style={{
                width: '100%',
                fontSize: '13px',
            }}
            placeholder={placeholderFilter}
            defaultValue={value}
            onChange={handleChange}
            options={data}
            // filterSort={(optionA, optionB) =>
            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            // }
        />
    )
}

export default Filter
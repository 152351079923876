import { LocalStorage } from '../localStorage/LocalStorage.services'

const getAllPayType = async () => {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pay-type`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token")
                },
            }
        )

        if (response.ok) {
            const data = await response.json()
            const dataNoDeleted = data.filter(element => {
                return element.payTypeDeleted === false
            })
            return  dataNoDeleted
        }
    } catch (error) {
        return {message: error.message || "Something went wrong"}
    }
    
}
    
export default getAllPayType
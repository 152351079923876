import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getSeguimentTypeById from '../../services/seguimentType/getSeguimentTypeById'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import postSeguimentType from '../../services/seguimentType/postSeguimentType'
import puSeguimentType from '../../services/seguimentType/puSeguimentType'

import styles from './NewSeguimentType.module.css'

const NewSeguimentType = () => {

    let navigate = useNavigate()

    const { id } = useParams()
    const [ newSeguimentType, setNewSeguimentType ] = useState({})
    const [ area, setArea ] = useState([])
    const [ active, setActive ] = useState(true)
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllHeadquarters()
        .then(area => {
            setArea(area)
        })
        if (id !== undefined) {
            getSeguimentTypeById(id)
            .then(seguimentType => {
                setNewSeguimentType(seguimentType)
                setActive(seguimentType.seguimentTypeActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('seguimentTypeActive', event.target.seguimentTypeActive.checked)
        const formJson = Object.fromEntries(formData.entries())
        if (id !== undefined) {
            puSeguimentType(formJson, id) 
            .then(newSeguimentType => {
                setNewSeguimentType(newSeguimentType)
            })
        } else {
            postSeguimentType(formJson) 
            .then(newSeguimentType => {
                setNewSeguimentType(newSeguimentType)
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/seguiment-type", { replace: true })
        },3000)
    }

    const handleChange = (event) => {
        if (event.target.id === "seguimentTypeActive") {
            setActive(event.target.checked)
        } else {
            setNewSeguimentType(event.target.value)
        }
    }
    
    return (
        <div className={styles.container}>
            <h2>{id ? "Editar tipus seguiment" : "Alta nou tipus seguiment"}</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Tipus seguiment modificat correctament" : "Tipus seguiment creat correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="seguimentTypeName">Nom</label>
                <input type='text' onChange={handleChange} name="seguimentTypeName" value={newSeguimentType.seguimentTypeName}></input>
                <label for="seguimentTypeAreaId">Àrea</label>
                <select onChange={handleChange} name="seguimentTypeAreaId" value={newSeguimentType.seguimentTypeAreaId} id="seguimentTypeAreaId" required>
                    {
                        area.map( element => {
                            return (
                                <option value={element._id}>{element.areaName}</option>
                            )
                        })
                    }
                </select>
                <label for="seguimentTypePrice">Preu</label>
                <input type='number' onChange={handleChange} name="seguimentTypePrice" value={newSeguimentType.seguimentTypePrice}></input>
                <label for="seguimentTypeActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="seguimentTypeActive" id="seguimentTypeActive" checked={active} className={styles.checking}/>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewSeguimentType
import { LocalStorage } from '../localStorage/LocalStorage.services'

const getOperations = async (id, year) => {

    const ident = id 
    const yearSelect = year

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/operations`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token"),
                    'id': ident,
                    'year': yearSelect
                },
            }
        )

        if (response.ok) {
            const data = await response.json()
            return data
        }
    } catch (error) {
        return {message: error.message || "Something went wrong"}
    }
    
}
    
export default getOperations
import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'

import getAllProfessionals from '../../services/professionals/getAllProfessional'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import desactiveProfessional from '../../services/professionals/desactiveProfessional'

import styles from './Professionals.module.css'

const Professionals = (props) => {

  const componentRef = useRef()
  
  const [ professionals, setProfessionals ] = useState([])
  const [ professionalsFiltered, setProfessionalsFiltered ] = useState([])
  const [ professionalsFiltered1, setProfessionalsFiltered1 ] = useState([])
  const [ professionalsFiltered2, setProfessionalsFiltered2 ] = useState([])
  const [ headquarters, setHeadquarters ] = useState([])
  const [ update, setUpdate ] = useState(false)
  
  useEffect(() => {
    getAllProfessionals()
    .then(elements => {
      setProfessionals(elements)
      setProfessionalsFiltered(elements)
      setProfessionalsFiltered1(elements)
      setProfessionalsFiltered2(elements)
    })
    getAllHeadquarters()
    .then(arees => {
      setHeadquarters(arees)
    })
  },[])

  // Actualizar els seguimients filtrats combinant ambdos filtres
  useEffect(() => {
    const uniqueValues = getCommonValues(professionalsFiltered1, professionalsFiltered2)
    setProfessionalsFiltered(uniqueValues)
  }, [professionalsFiltered1, professionalsFiltered2])


  function getCommonValues(...arrays) {
    return arrays.reduce((acc, currentArray) => {
      return acc.filter((value) => currentArray.includes(value))
    })
  }

  const handleDesactive = (event) => {
    desactiveProfessional(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula de professionals",
    onafterprint: () => alert('Print success')
  })
  
  const titles = [ "Professional", "DNI", "E-mail", "Perfil", "Àrea", "Estat", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por usuari
  const handleChangeProfessional = (value) => {
    if (value.length !== 0) {
      let professionalsFiltered = professionals.filter(element =>  value.includes(element._id)) 
      setProfessionalsFiltered1(professionalsFiltered)
    } else {
      setProfessionalsFiltered1(professionals)
    }
  }

  // Placeholder del filtro de usuaris
  const placeholderFilterProfessionals = "Filtre usuari"

  // Array con los elementos del filtro de usuaris
  const dataProfessionals = professionals && professionals.map(element => {
    return {
      label: `${element.professionalName} ${element.professionalLastName1} ${element.professionalLastName2}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por headquarters
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      let professionalsFiltered = professionals.filter(element =>  value.includes(element.professionalAreaId._id)) 
      setProfessionalsFiltered2(professionalsFiltered)
    } else {
      setProfessionalsFiltered2(professionals)
    }
  }

  // Placeholder del filtro de headquarters
  const placeholderFilterHeadquarters = "Filtre àrea"

  // Array con los elementos del filtro de headquarters
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })
  
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
              data={dataProfessionals} 
              handleChange={handleChangeProfessional}
              placeholderFilter={placeholderFilterProfessionals}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataHeadquarters} 
              handleChange={handleChangeHeadquarter}
              placeholderFilter={placeholderFilterHeadquarters}
              type='multiple'
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button handleOnClick={handlePrint} icon={"fa-print"}/>
          <Button path={props.professionalProfile === "Administrador" && "/professionals/new-professional"} icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3" className={styles.etable}>
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                professionals && professionalsFiltered.map(element => {
                  let url = '/professionals/' + element._id
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.professionalLastName1} {element.professionalLastName2}, {element.professionalName}</td>
                      <td className={styles.columns}>{props.professionalProfile === "Administrador" ? element.professionalDNI : "*****************"}</td>
                      <td className={styles.columns}>{element.professionalEmail}</td>
                      <td className={styles.columns}>{element.professionalProfile}</td>
                      <td className={styles.columns}>{element.professionalAreaId.areaName}</td>
                      <td className={styles.columns}>{element.professionalActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={props.professionalProfile === "Administrador" && url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        {
                          props.professionalProfile === "Administrador" ? <i className="fa-solid fa-trash" onClick={handleDesactive}></i> : <i className="fa-solid fa-trash"></i>
                        }  
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Professionals

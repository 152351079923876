import { NavLink } from 'react-router-dom'

import styles from './NavBar.module.css'

const NavBar = (props) => {

    return (
        <nav>
            <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/home">Home</NavLink>
            <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/seguiments">Seguiments</NavLink>
            <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/usuaris">Usuaris</NavLink>
            <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/referents">Referents</NavLink>
            <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/professionals">Professionals</NavLink>
            <NavLink className={({isActive})=> isActive ? `${styles.active} ${props.professionalProfile !== "Administrador" && styles.admin}` : `${styles.link} ${props.professionalProfile !== "Administrador" && styles.admin}`} to="/seguiment-type">Tipus</NavLink>
            <NavLink className={({isActive})=> isActive ? `${styles.active} ${props.professionalProfile !== "Administrador" && styles.admin}` : `${styles.link} ${props.professionalProfile !== "Administrador" && styles.admin}`} to="/pay-type">Pagaments</NavLink>
            <NavLink className={({isActive})=> isActive ? `${styles.active} ${props.professionalProfile !== "Administrador" && styles.admin}` : `${styles.link} ${props.professionalProfile !== "Administrador" && styles.admin}`} to="/centres">Arees</NavLink>
            <NavLink className={({isActive})=> isActive ? `${styles.active} ${props.professionalProfile !== "Administrador" && styles.admin}` : `${styles.link} ${props.professionalProfile !== "Administrador" && styles.admin}`} to="/empreses">Empresa</NavLink>
        </nav>
    )
}

export default NavBar
